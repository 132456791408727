.navbarc {
    background:#210B65;
}
p, li, i { 
    font-size: 12px;
}
.sm { 
    font-size: 11px;
}

/* Estilos generales */
body {
    background-color: #f8f9fa;
}

/* Estilos de paginación */
.pagination {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.pagination .page-item {
    list-style: none;
    width: 40px;
}

.pagination .page-link {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 4px;
    color: #210B65;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

/* Estilo especial para flechas de navegación */
.pagination .page-item:first-child .page-link,
.pagination .page-item:last-child .page-link {
    font-size: 1.2rem;
    font-weight: bold;
}

.pagination .page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
    color: #150740;
}

.pagination .active .page-link {
    background-color: #210B65;
    border-color: #210B65;
    color: #fff;
}

.pagination .disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
    border-color: #dee2e6;
}

/* Estilo para los puntos suspensivos */
.pagination .page-item.break .page-link {
    background-color: transparent;
    border: none;
    color: #6c757d;
}

/* Estilos de las cards */
.card {
    transition: transform 0.2s ease-in-out;
}

/* El efecto hover solo para las cards de usuarios */
.user-card:hover {
    transform: translateY(-2px);
}

/* Estilos responsivos para las cards */
@media (max-width: 768px) {
    .container {
        padding: 10px;
    }
    
    .card {
        margin-bottom: 15px;
    }

    .card-body {
        padding: 10px;
    }

    .table-responsive {
        margin: 0 -10px;
    }

    .table td, .table th {
        padding: 5px;
    }

    .btn {
        padding: 0.375rem 0.5rem;
    }

    .input-group {
        flex-wrap: nowrap;
    }

    .form-control {
        font-size: 14px;
    }

    h4 {
        font-size: 1.2rem;
    }

    .pagination .page-item {
        width: 35px;
    }

    .pagination .page-link {
        width: 35px;
        height: 35px;
        font-size: 0.9rem;
    }
}

/* Ajustes para pantallas muy pequeñas */
@media (max-width: 576px) {
    .row {
        margin: 0;
    }

    .col-md-4, .col-md-6 {
        padding: 5px;
    }

    .card-header {
        padding: 10px;
    }

    .table td, .table th {
        padding: 3px;
        font-size: 12px;
    }

    .btn-sm {
        padding: 0.2rem 0.4rem;
        font-size: 0.75rem;
    }

    .pagination {
        gap: 3px;
    }

    .pagination .page-item {
        width: 30px;
    }

    .pagination .page-link {
        width: 30px;
        height: 30px;
        font-size: 0.8rem;
    }

    .navbarc img {
        max-height: 40px;
    }
}

/* Estilos para la impresión */
@media print {
    @page {
        margin: 0;
        size: A4;
    }

    body {
        background-color: white;
        margin: 0;
        padding: 0;
    }

    .container {
        max-width: 100% !important;
        width: 210mm !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .card {
        border: none !important;
        box-shadow: none !important;
        break-inside: avoid;
        page-break-inside: avoid;
        height: 148.5mm !important;
        max-height: 148.5mm !important;
        width: 210mm !important;
        overflow: hidden !important;
        margin: 0 !important;
        padding: 10mm 15mm !important;
        position: relative !important;
    }

    .card-body {
        padding: 0 !important;
        height: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        position: relative !important;
    }

    .card-footer,
    .pagination,
    .btn,
    nav,
    .container > .d-flex,
    .card.shadow-sm:not(.user-card),
    footer,
    #footer,
    .footer {
        display: none !important;
    }

    .table-responsive {
        overflow: visible !important;
    }

    .table {
        font-size: 10px !important;
        margin-bottom: 5mm !important;
    }

    p, li { 
        font-size: 10px !important;
        margin-bottom: 2px !important;
        line-height: 1.2 !important;
    }

    h4 {
        font-size: 14px !important;
        margin-bottom: 5px !important;
    }

    .row {
        margin: 0 !important;
    }

    .mt-auto {
        margin-top: auto !important;
        position: absolute !important;
        bottom: 0 !important;
        width: calc(100% - 30mm) !important;
    }

    img {
        max-height: 20mm !important;
    }

    ::-webkit-scrollbar {
        display: none !important;
    }

    * {
        margin-bottom: 0 !important;
    }

    .card::after {
        display: none !important;
        content: none !important;
    }
}

/* Estilos para los filtros */
.form-control:focus,
.form-select:focus {
    border-color: #210B65;
    box-shadow: 0 0 0 0.25rem rgba(33, 11, 101, 0.25);
}

/* Estilos para los botones */
.btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

/* Estilos para las tablas */
.table-sm td,
.table-sm th {
    font-size: 0.875rem;
}

/* Estilos para los inputs */
.form-control::placeholder {
    color: #adb5bd;
}

/* Animaciones */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    animation: fadeIn 0.3s ease-in-out;
}

/* Estilos para el scrollbar - Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background: #210B65;
    border-radius: 6px;
    border: 3px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
    background: #150740;
}

/* Estilos para el scrollbar - Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #210B65 #f1f1f1;
}

/* Estilos para evitar el salto de contenido al cambiar de página */
html {
    scrollbar-gutter: stable;
}

/* Ajustes responsivos adicionales */
.navbarc {
    background: #210B65;
    padding: 0.5rem 1rem;
}

@media (max-width: 992px) {
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .card-body {
        padding: 15px;
    }

    .btn {
        white-space: nowrap;
    }
}

/* Ajustes para formularios responsivos */
@media (max-width: 768px) {
    .form-label {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

    .input-group-text {
        font-size: 14px;
        padding: 0.3rem 0.5rem;
    }

    .form-control, .form-select {
        height: auto;
        padding: 0.3rem 0.5rem;
    }

    .btn-group {
        flex-wrap: wrap;
        gap: 5px;
    }

    .d-flex {
        flex-wrap: wrap;
        gap: 10px;
    }
}

/* Ajustes para el login responsivo */
@media (max-width: 576px) {
    .container {
        padding: 15px;
    }

    form {
        width: 100%;
    }

    .my-4 {
        margin: 1rem 0 !important;
    }

    .btn {
        width: 100%;
        margin: 5px 0;
    }
}
